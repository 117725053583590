<template>
  <div>
    <v-card elevation="2" tile>
      <v-card-title
        >Configuración para Ejecutar el Consolidado de ConsolidadoArchivosService
        {{ consolidacion.nombre }}</v-card-title
      >
      <v-divider></v-divider>
      <v-form v-model="valid">
        <v-container>
          
          <v-row>
            <v-col cols="12" md="12">
              <div class="text-center" v-if="!relacionFuenteArchivo.length">
                <div class="spinner-border" role="status"></div>
              </div>

              <v-data-table
                v-if="relacionFuenteArchivo.length"
                :headers="headers"
                :items="relacionFuenteArchivo"
                :items-per-page="-1"
                class="elevation-1"
                hide-default-footer
              >
                
                <template v-slot:item.archivos="{item}">
                  <div
                    v-if="!item.archivos.length"
                    class="spinner-border"
                    role="status"
                  ></div>
                  
                  <v-select
                    v-if="item.archivos.length"
                    v-model="item.archivos.selected"
                    :items="item.archivos"
                    :item-text="'id_nombre'"
                    :item-value="'id'"
                    attach
                    :label="
                      'Seleccionar Archivo'
                    "
                    :rules="required"
                    return-object
                  ></v-select>
                  <div v-if="debug">
                  {{item.archivos}}
                  </div> 
                </template>
              </v-data-table>
            </v-col>
          </v-row>

        </v-container>
      </v-form>
      <v-divider></v-divider>
      <!-- <pre>{{proceso}}</pre> -->
      <v-card-actions>
        <v-spacer></v-spacer>
        <CButton color="secondary" @click="preCancelar">Cancelar</CButton>&nbsp;
        <div v-if="loadingEjecutar" class="spinner-border" role="status"></div>
        <CButton
          v-if="!loadingEjecutar"
          color="primary"
          class="px-4"
          @click="ejecutarConciliado()"
          :disabled="!valid"
          >Ejecutar</CButton
        >
      </v-card-actions>
    </v-card>
    <v-switch v-model="debug" :label="`Mostrar metadata de archivos`"></v-switch>
    
    <v-dialog v-model="dialog" max-width="420">
      <v-card>
        <v-card-title class="text-h5">
          ¿Desea cancelar la edición?
        </v-card-title>

        <v-card-text>
          Si cancela la edición los datos diligenciados no quedarán guardados.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="goBack">
            Cancelar y volver
          </v-btn>

          <v-btn color="green darken-1" text @click="dialog = false">
            Continuar la edición
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import EjecucionConsolidadoArchivosService from "@/services/ejecucionConsolidadoArchivos.service";
import ArchivoService from "@/services/archivo.service";
import LayoutService from "@/services/layout.service";
import { mapState } from "vuex";

export default {
  name: "Calidad",

  data() {
    return {
      consolidacion: {
        id: undefined,
        nombre: undefined,
        descripcion: undefined,
        layout_id: undefined,
        relaciones: [],
        campos: [],
        agrupar: 1,
        activo: 1,
      },
      debug: false,
      valid: false,
      rules: [
        (value) => !!value || "Requerido",
        (value) => (value && value.length >= 3) || "Mínimo 3 caracteres",
      ],
      required: [(value) => !!value || "Requerido"],
      headers: [
        { text: "Layout id", value: "layout_id", sortable: false },
        { text: "Seleccionar Archivo de la Fuente", value: "archivos", sortable: false },
      ],
      archivo: undefined,
      archivoSecundario: undefined,
      archivos: [],
      relacionFuenteArchivo: [],
      relacionesArray: [],
      pasos: [],
      fuentes: [],
      loading: false,
      loadingArchivos: false,
      loadingLayouts: false,
      loadingTiposOperacion: false,
      loadingPeriodicidades: false,
      loadingRoles: false,
      loadingEjecutar: false,
      message: "",
      dialog: false,
    };
  },
  computed: {
    ...mapState(["permissions"]),
  },
  watch: {},
  mounted() {
    if (!this.permissions.includes("Ejecutar Proceso Consolidacion"))
      this.$router.push("/dashboard");

    if (localStorage.getItem("datos_consolidado_archivos")) {
      this.consolidacion = JSON.parse(localStorage.getItem("datos_consolidado_archivos"));
      //this.fetchArchivos("");
      this.cargarRelacionFuenteArchivo();
    }

    this.fetchLayouts("");
  },
  methods: {
    cargarRelacionFuenteArchivo() {
      let _this = this
      let layouts_array=[]
      console.log(this.consolidacion.relaciones)
      this.relacionesArray = JSON.parse(this.consolidacion.relaciones);
      this.relacionesArray.forEach(rel => {
        layouts_array.push(rel.layout_id1)
        layouts_array.push(rel.layout_id2)
      });
      layouts_array=layouts_array.filter((item,index) => layouts_array.indexOf(item) === index)
      layouts_array.forEach(ly =>{
        this.fetchArchivosFuentes(ly)
      });
    },   
    ejecutarConciliado() {  
      this.loadingEjecutar = true;
      console.log("-------------------------- Ejecutar Conciliado --------------")
      console.log(this.relacionFuenteArchivo)
      let selected = []
      this.relacionFuenteArchivo.forEach(rel => {
        console.log(rel)
        console.log(rel.archivos)
        console.log(rel.archivos.selected)
        let obj={layout_id: rel.layout_id, archivo:rel.archivos.selected };
        selected.push(obj)
      })
      console.log(selected)
      EjecucionConsolidadoArchivosService.ejecutar(this.consolidacion, { archivos: selected }).then(
        (response) => {
          console.log(response.data);
          this.loadingEjecutar = false;
          this.$router.push({ path: `/cargue-archivos/consolidado-archivos` });
        },
        (error) => {
          this.content =
            ( error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loadingEjecutar = false;
          this.$router.push({ path: `/cargue-archivos/consolidado-archivos` });
        }
      );
    },
    fetchArchivos(cadenaBusqueda) {
      this.loadingArchivos = true;
      console.log(this.consolidacion);
      ArchivoService.getItems(this.consolidacion.layout_id, cadenaBusqueda).then(
        (response) => {
          this.archivos = response.data;
          this.archivos = this.archivos.map(function f(x) {
            return { ...x, id_nombre: x.id + " - " + x.nombre };
          });
          console.log(response.data);
          this.loadingArchivos = false;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loadingArchivos = false;
        }
      );
    },
    fetchArchivosFuentes(index) {
      ArchivoService.getItems(index, '').then(
        (response) => {
          console.log(response)
          console.log("fetchArchivosFuentes")
          let obj={layout_id: index, archivos: response.data };
          obj.archivos = obj.archivos.map(function f(x) {
            return { ...x, id_nombre: x.id + " - " + x.nombre };
          });
          this.relacionFuenteArchivo.push(obj)
          
          console.log(this.relacionFuenteArchivo)
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loadingArchivos = false;
        }
      );
    },
    fetchLayouts(cadenaBusqueda) {
      this.loadingLayouts = true;
      LayoutService.getItems(cadenaBusqueda).then(
        (response) => {
          this.fuentes = response.data;
          this.loadingLayouts = false;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loadingLayouts = false;
        }
      );
    },
    preCancelar() {
      this.dialog = true;
    },
    goBack() {
      this.$router.push({ path: "/cargue-archivos/consolidado-archivos" });
    },
  },
};
</script>

<style>
</style>
