import api from './api';

class EjecucionConsolidadoArchivosService {

  ejecutar(consolidado, request) {
    console.log(consolidado)
    console.log(request)
    console.log("retorno 200");
    //return "200";
    return api.post(`/api/ejecutar_consolidado_archivos/${consolidado.id}`, request);
  }
}

export default new EjecucionConsolidadoArchivosService();
